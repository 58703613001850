window.addEventListener('DOMContentLoaded', function(){
    window.addEventListener('scroll', function(){
        let elements = document.getElementsByClassName("header-wrap__logo");
        if (window.scrollY > 100) {
            elements[0].classList.add("scroll");
        }else {
            elements[0].classList.remove("scroll");
        }
    });
});

function setHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  // 2.初期化
  setHeight();
  
  // 3.ブラウザのサイズが変更された時・画面の向きを変えた時に再計算する
  window.addEventListener('resize', setHeight);

$("a").click(function(){
    // 別ページの場合は以下
    let a = $(this).attr("href");
    let url = "#" + a.split("#")[1]
    if (url) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function(){
        // ヘッダー固定の場合はヘッダーの高さを数値で入れる、固定でない場合は0
            var headerHight = $('header').outerHeight();
            var sp_headerHight = $('.header-humberger').outerHeight();
            var target = $(url);
            var position = target.offset().top - headerHight;
            var sp_position__02 = target.offset().top - sp_headerHight;

            if (window.matchMedia('(max-width: 767px)').matches) {
                bodyFixedOff();
                $("#js-menu").fadeOut(200);
                $(".humberger-fade-item").removeClass('action');
                $("#js-menu-open").removeClass('open');

                $('body,html').stop().animate({scrollTop:sp_position__02}, 500);
                return false;
            }else {
                $('body,html').stop().animate({scrollTop:position}, 500);
            }
        }, 100);
    }
});

// // onloadはページを読み込んだ時のみに実行される（同一ページの場合すでに読み込んでいるから実行されない）
window.onload = function(){
    // 別ページの場合は以下
    var urlHash = location.hash;
    if (urlHash) {
        $('body,html').stop().scrollTop(0);
        setTimeout(function(){
        // ヘッダー固定の場合はヘッダーの高さを数値で入れる、固定でない場合は0
            var headerHight = $('header').outerHeight();
            var sp_headerHight = $('.header-humberger').outerHeight();
            var target = $(urlHash);
            var position = target.offset().top - headerHight;
            var sp_position = target.offset().top - sp_headerHight;

            if (window.matchMedia('(max-width: 767px)').matches) {
                bodyScrollLock.clearAllBodyScrollLocks();
                $("#js-menu").fadeOut(200);
                $(".humberger-fade-item").removeClass('action');
                $("#js-menu-open").removeClass('open');
                $('body,html').stop().animate({scrollTop:sp_position}, 500);
            }else {
                $('body,html').stop().animate({scrollTop:position}, 500);
            }
        }, 100);
    }
};

//変数の設定
var $body = $('body');

//スクロール量を保存
var scrollTop;

//スクロールを固定
function bodyFixedOn() {
    scrollTop = $(window).scrollTop();

    $body.css({
        position: 'fixed',
        top: -scrollTop,
        'overflow-y': 'hidden'
    });
}

//スクロールの固定を解除
function bodyFixedOff() {
    $body.css({
        position: '',
        top: '',
        'overflow-y': ''
    });
    
    $(window).scrollTop(scrollTop);
}

$(function(){
    // ハンバーガーメニュー
    let menu = $("#js-menu");
    $("#js-menu-open").click(function(){
        $(this).toggleClass('open');
        if($(this).hasClass('open')){
            menu.fadeIn(400).css('display','flex');
            $(".humberger-fade-item").addClass('action');
            bodyFixedOn();
        }else{
            menu.fadeOut(200);
            $(".humberger-fade-item").removeClass('action');
            bodyFixedOff();
        }
    });
});

// header hover
$(function(){
    $(".item").hover(
        function(){
            $(this).removeClass("item");
            $(this).prev("a").addClass("active");
        },
        function(){
            $(this).addClass("item");
            $(this).prev("a").removeClass("active");
        }
    );
});

// fadeIn
$('header').on('inview', function(event, isInView) {
    if (isInView) {
        $(this).addClass("fade-box");
    }
});
